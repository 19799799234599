/* eslint-disable react/jsx-no-target-blank */

import React,{ useState, useEffect } from 'react';
import { useLocation , Link} from 'react-router-dom';

const FooterOne = () => {

  const { pathname } = useLocation();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <footer class="bg-dark main-cds-footer">
        <div class="container custom-cn-width">
          <div class="row align-items-center py-2">
            <div class="col-md-7">
              <p class="copyright f-15 opacity-75">
                © 2004 -
                <script>document.write(new Date().getFullYear())</script>2024 <a href="https://capdigisoft.com/" className="fw-medium" style={{textDecoration:"none", color:"#52bdc7"}}>CAP Digisoft Solution</a>, Inc. All Rights Reserved.
              </p>
            </div>
            <div class="col-md-5 text-lg-end">
              <ul class="list-unstyled list-inline mb-0 footer-social ">
                <li class="list-inline-item f-15">Stay in the Loop?</li>
                <li class="list-inline-item fb opacity-75">
                  <a
                    title="Facebook"
                    href="https://www.facebook.com/capdigisoftsolutions/"
                    target="_blank"
                  >
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li class="list-inline-item ln opacity-75">
                  <a
                    title="Linked In"
                    href="https://in.linkedin.com/company/cap-digisoft-solutions-inc."
                    target="_blank"
                  >
                    <i class="fab fa-linkedin-in"></i>
                  </a>
                </li>
                <li class="list-inline-item tw opacity-75">
                  <a
                    title="twitter"
                    href="https://twitter.com/CAPDigisoft"
                    target="_blank"
                  >
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li class="list-inline-item tw opacity-75">
                  <a
                    target="_blank"
                    title="Youtube"
                    href="https://www.youtube.com/channel/UCGgLVDZx1q4I4F99IEx9Apw"
                  >
                    <i class="fab fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>

      {showButton && (
    <div className="scroll-up">
        <Link to="javascript:void(0)" onClick={scrollToTop} className="js-scroll-trigger scroll-to-top"><i className="fa fa-angle-up"></i></Link>
    </div>
    )}
    </>
  );
};

export default FooterOne;
